<template>
  <div class="mt-5 mb-4">
    <hr>
    <div class="item-top-area d-flex align-items-center">
      <div class="item-right mr-auto">
        <div class="switch-toggle">
          <span>📐 Custom Height</span>
        </div>
      </div>
      <div class="item-right ml-auto">
        <div class="switch-toggle">
          <label class="switch" v-tooltip="heightAllowed && heightAllowed.error" for="show_title">
            <input @click="toggleHeight()" :disabled="heightAllowed && heightAllowed.allowed" :checked="heightObj.height_enabled" :value="heightObj.height_enabled"
                   type="checkbox"
                   id="show_title"/>
            <div class="slider round"></div>
          </label>
        </div>
      </div>
    </div>
    <div v-if="heightObj.height_enabled" class="mt-3 input_icon_text d-flex align-items-center">
      <div class="content">
        <div class="url">Height</div>
        <div class="name">
          <div class="validation_input">
            <input type="range" v-tooltip="heightObj.height + ' px'" min="160" v-model="heightObj.height" max="800"
                   value="199">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default ({
  data () {
    return {
      heightObj: {
        height_enabled: this.height_enabled,
        height: this.height
      }
    }
  },
  created () {
    this.heightObj.height_enabled = this.height_enabled
    this.heightObj.height = this.height
  },
  props: [
    'height',
    'height_enabled',
    'heightAllowed'
  ],
  methods: {
    toggleHeight () {
      this.heightObj.height_enabled = !this.heightObj.height_enabled
      this.$emit('heightObj', this.heightObj)
    }
  },
  watch: {
    'heightObj.height' () {
      this.$emit('heightObj', this.heightObj)
    }
  }

})
</script>

<style scoped lang="less">

</style>
